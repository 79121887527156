import { SellersService } from 'app/providers/api/services';
import { useApiMutation } from 'shared/hooks/useApiMutation';

interface Args {
  refetchInvitedUsers: () => void;
}

export default ({ refetchInvitedUsers }: Args) => {
  const { mutate: sellerEditUser } = useApiMutation(
    ['sellerEditUser'],
    SellersService.sellerEditUser,
    { onSuccess: () => refetchInvitedUsers() },
  );

  return {
    sellerEditUser,
  };
};
