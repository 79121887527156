/* eslint-disable max-len */
export const generalFields: {
  name: keyof {
    title: string;
    legalEntityName: string;
    legalEntityType: string;
    companyMission: string;
  };
  label: string;
  tooltip: string;
}[] = [
  {
    name: 'title',
    label: 'Company Name (DBA)',
    tooltip:
      'Provide the name most recognized by customers. This is how customers will find your company in the marketplace.',
  },
  {
    name: 'legalEntityName',
    label: 'Legal Entity Name',
    tooltip:
      'Provide the official name of the business. This is usually the name that appears on the business formation documents, and used to sign contracts.',
  },
  {
    name: 'legalEntityType',
    label: 'Legal Entity Type',
    tooltip:
      "Your company's legal entity type can be confirmed by checking the business formation documents or tax returns.",
  },
  {
    name: 'companyMission',
    label: 'Company Description',
    tooltip:
      'The company description should describe your company to potential buyers in the marketplace. Consider letting buyers know who you are, and how your business can support customers. Below is a sample opening for your company description: \n\n”Rodgers Supply is a family-owned HVAC contractor service operating since 1988. We service commercial, industrial, and residential customers throughout Texas. We offer a full range of services, from HVAC service and repairs to brand new installs.”',
  },
];

export const productOrderingFields = (
  companyName: string,
): {
  name: keyof {
    applicationEmail: string;
    applicationUrl: string;
  };
  label: string;
  placeholder: string;
  tooltip: string;
}[] => [
  {
    name: 'applicationEmail',
    label: 'Account activation email',
    placeholder: 'contact@example.com',
    tooltip: `Customers will send their ${companyName} account numbers to this email for your confirmation.`,
  },
  {
    name: 'applicationUrl',
    label: 'Account application URL',
    placeholder: 'https://example.com',
    tooltip: `Link to the page where prospective customers can apply for a new ${companyName} account.`,
  },
];

export const contactInfoFields: {
  name: keyof {
    phone: string;
    email: string;
    ['company website']: string;
  };
  label: string;
  placeholder: string;
}[] = [
  {
    name: 'phone',
    label: 'Phone (Customer Support)',
    placeholder: '(___) ___-____',
  },
  {
    name: 'email',
    label: 'Email (Customer Support)',
    placeholder: 'contact@example.com',
  },
  {
    name: 'company website',
    label: 'Company Website',
    placeholder: 'https://example.com',
  },
];

export const inviteUserFields: {
  name: keyof {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
  label: string;
  placeholder: string;
}[] = [
  {
    name: 'firstName',
    label: 'First Name *',
    placeholder: 'First Name',
  },
  {
    name: 'lastName',
    label: 'Last Name *',
    placeholder: 'Last Name',
  },
  {
    name: 'email',
    label: 'Email *',
    placeholder: 'contact@example.com',
  },
  {
    name: 'phone',
    label: 'Phone',
    placeholder: '(___) ___-____',
  },
];

export const legalEntityTypes = [
  { label: 'Sole Proprietorship', value: 'Sole Proprietorship' },
  { label: 'Partnership', value: 'Partnership' },
  { label: 'Limited Liability Company', value: 'Limited Liability Company' },
  { label: 'Corporation', value: 'Corporation' },
  { label: 'Nonprofit corporation', value: 'Nonprofit corporation' },
  { label: 'Cooperative', value: 'Cooperative' },
];

export const policiesInfoFields: {
  name: string;
  label: string;
}[] = [
  { name: 'terms', label: 'Terms and Conditions' },
  { name: 'privacy', label: 'Privacy Policy' },
  { name: 'returnPolicy', label: 'Return Policy' },
  { name: 'shipping', label: 'Shipping Policy' },
];

export const sellerTypes = [
  { id: 'seller', label: 'Seller', value: 'seller' },
  { id: 'service_provider', label: 'Service Provider', value: 'service' },
];

export const userRoles = [
  { id: '6e12fbae', label: 'Seller Administrator' },
  { id: 'f6f0e789', label: 'Seller Developer' },
];
